/**
 * Base content pick, which can be extended
 */
 .content-pick {
  display: flex;
  align-items: space-between;
  align-content: space-between;
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    margin: 0 0 0.2rem 0;
  }

  &__highlight {
    padding: 1rem 0.5rem;
    width: 4.6em;
    min-width: 4.6em;
    line-height: 1.2;
    text-align: center;
    border-right: 1px solid $backgroundColorGreyLight;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .value {
      font-size: 1.55rem;
      display: block;
      margin-bottom: 0.4rem;
    }
    .unit {
      font-size: 0.9rem;
      /*font-size: 0.7rem;
      margin: auto;*/
    }

    &__icon {
      height: 100%;
      display: flex;
      font-size: 2rem;
      justify-content: center;
      align-items: center;
    }
  }

  &__date,
  &__meta {
    font-size: 0.85rem;
    margin-bottom: 0.3rem;
  }
  &__sticky {
    margin-left: 0.5rem;
  }
  &__external-link {
    position: absolute;
    right: .8rem;
    top: .8rem;
  }
  &__yit {
    position: absolute;
    right: .8rem;
    top: .8rem;
    + .content-pick__external-link {
      right: 3rem;
    }
  }

  &__headline {
    font-size: 0.85rem;
    margin-bottom: 0.3rem;
  }
  &__title {
    font-family: $fontPrimaryBold;
    padding-right: 1.25rem;
    margin-bottom: 0;
  }
  &__title--medium {
    font-family: $fontPrimaryMedium;
    padding-right: 1rem;
  }

  &__description {
    font-size: 0.9rem;
    margin: 1rem 0;
    p {
      margin: 0;
    }
  }

  &__icon {
    padding-right: 1em;
    font-size: 1.3em;
  }

  &__arrow-link {
    font-size: 1.3rem;
    margin: auto 1rem auto auto;
    width: 1rem;
    text-align: right;
    display: block;
  }

  &__notification-count {
    position: absolute;
    display: block;
    min-width: 1.35rem;
    min-height: 1.2rem;
    line-height: 1;
    top: -0.2rem;
    right: -0.2rem;
    padding: 0.3rem;
    text-align: center;
    font-size: 0.8rem;
    border-radius: 50%;
    background: $red;
    color: #fff;
  }

  &__content {
    padding: 0.8rem 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (min-width: $breakpoint-md) {
      padding: 1rem 1rem;
    }
  }

  &--box {
    padding: 0;
    margin-bottom: 1rem;
    .content-pick__content {
      padding: 0.8rem 1rem;
      @media screen and (min-width: $breakpoint-md) {
        padding: 1rem 1rem;
      }
    }
    .content-pick__arrow-link {
      bottom: 0;
      top: auto;
    }
    .content-pick__thumbnail {
      height: auto;
    }
  }

  &__cta {
    margin-top: 0.8rem;

    .button {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
    a, button.button--link {
      color: $blueMedium !important;
      border-bottom-color: $blueMedium !important;
    }
  }

  /* Thumbnail */
  &__thumbnail {
    min-width: 75px;
    max-width: 75px;
    width: 100%;
    height: auto;
    min-height: 75px;

    //use image as background image
    &--bg {
      background-size: cover;
      background-position: 50% 50%;
    }

    img {
      width: 100%;
      height: auto;
    }

    &.medium {
      min-width: 120px;
      max-width: 120px;
      min-height: 120px;
    }

    &.large {
      min-width: 120px;
      max-width: 120px;
      min-height: 120px;
      @media screen and (min-width: $breakpoint-md) {
        min-width: 180px;
        max-width: 180px;
        min-height: 180px;
      }
    }

    &--placeholder {
      display: flex;
      height: 100%;
      background: $greyBlueMidLight;
      align-items: center;
      justify-content: center;
      i {
        opacity: .5;
        font-size: 2rem;
      }
    }
  }

  &__footer {
    font-size: 0.85rem;
    margin-top: auto;
  }

  @media screen and (min-width: $breakpoint-md) {
    height: calc(100% - 1rem); //100% - margin-bottom 1rem
  }

  @media screen and (min-width: $breakpoint-lg) {
    .content-pick__arrow-link {
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &:active,
    &:focus {
      .content-pick__arrow-link {
        transform: translateX(0.4rem);
      }
    }
  }

  &--hover-with-bg {
    transition: all 0.2s ease-in-out;

    @media screen and (min-width: $breakpoint-lg) {
      &:hover {
        background: $backgroundHoverColor;
      }
    }
  }

  /* Transform item to vertical box */
  &--direction-vertical {
    flex-direction: column;
    padding: 0;

    .content-pick__cta {
      margin-top: 1.5rem;
    }

    .content-pick__thumbnail {
      max-width: none !important;
      width: 100%;
      height: 120px;
      &.large {
        height: 150px;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      .content-pick__content {
        padding: 1.5rem;
      }
    }
  }

  &__priority-item {
    position: absolute;
    z-index: 1;
    left: -.6rem;
    top: -.6rem;
    font-size: 1.6rem;
    color: $red;
    background-color: #fff;
    animation: pulse-red 1.5s linear 1s infinite;
    border-radius: 50%;
  }

  &--disabled {
    opacity: 0.6;
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba($red, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($red, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($red, 0);
  }
}